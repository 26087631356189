import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import styled from '@emotion/styled';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Section, Container, Grid, SVGWrapper, Flex } from '../components';
import { ResponsiveContext } from '../providers/responsive';
import { ThemeContext } from '../providers/themes';
import LinkArrowSVG from '../images/link-arrow.svg';
import StartTinySVG from '../images/star-tiny.svg';

const ContactSchema = Yup.object().shape({
  fullName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  message: Yup.string().required('Required'),
  agreedToPrivacyPolicy: Yup.boolean().oneOf(
    [true],
    'You must accept the terms and conditions to continue',
  ),
});

const StyledField = styled(Field)((props) => {
  const sharedStyles = {
    borderRadius: 0,
    color: props.theme.text,
    backgroundColor: 'transparent',
    display: 'block',
    width: '100%',
    marginTop: '1.375rem',
    fontSize: '1rem',

    '&::placeholder': {
      color: props.theme.text,
    },
  };

  if (props.as && props.as === 'textarea') {
    return {
      ...sharedStyles,
      marginTop: '0.313rem',
      border: props.error
        ? `solid 1px ${props.theme.error}`
        : `solid 1px ${props.theme.lines}`,
      minHeight: '6.875rem',
      padding: '0.313rem',
    };
  } else if (props.type === 'checkbox') {
    return {
      ...sharedStyles,
      width: '1.438rem',
      height: '1.438rem',
      margin: '0 0.938rem 0 0',
      borderRadius: 0,
      border: props.error
        ? `solid 1px ${props.theme.error}`
        : `solid 1px ${props.theme.lines}`,
    };
  } else {
    return {
      ...sharedStyles,
      border: 'none',
      borderBottom: props.error
        ? `solid 1px ${props.theme.error}`
        : `solid 1px ${props.theme.lines}`,
      paddingBottom: '0.313rem',
    };
  }
});

const Label = styled.label((props) => ({
  color: props.theme.text,
  fontSize: '1rem',
  marginTop: '1.375rem',
  display: 'block',
}));

const CheckboxLabel = styled.label((props) => ({
  fontSize: '1rem',
  marginTop: '1.375rem',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: props.theme.text,
}));

const Feedback = styled.div((props) => ({
  fontSize: '0.8rem',
  color: props.theme.text,
}));

const SubmitButton = styled.button((props) => ({
  backgroundColor: props.theme.buttons?.background,
  color: props.theme.buttons?.text,
}));

const FormSection = styled.div((props) => {
  if (props.responsive.isDesktopUp) {
    return {
      borderRight: `solid 1px ${props.theme.lines}`,
    };
  }
  return {};
});

const FormHeading = styled.h3((props) => ({
  color: props.theme.text,
}));

const ContactInfoHeading = styled.h3((props) => ({
  color: props.theme.text,
}));

const StyledSocialLink = styled.a((props) => ({
  color: props.theme.text,
  borderBottom: `solid 1px ${props.theme.lines}`,
}));

const ContactLink = styled.a((props) => ({
  color: props.theme.text,
}));

const CopyRightWrapper = styled(Flex)((props) => {
  if (props.responsive.isDesktopUp) {
    return {
      marginTop: 'auto',
    };
  }
  return {};
});

const CopyRight = styled.div((props) => ({
  color: props.theme.text,
  maxWidth: '10.063rem',
  fontSize: '0.875rem',
}));

const SocialLink = ({ children, theme, ...otherProps }) => (
  <StyledSocialLink {...{ theme }} target="_blank" {...otherProps}>
    {children}
    <SVGWrapper color={theme.text}>
      <LinkArrowSVG />
    </SVGWrapper>
  </StyledSocialLink>
);

const Contact = (props) => {
  const intl = useIntl();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const currentYear = new Date().getFullYear();

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
      )
      .join('&');
  };

  const handleSubmit = (values) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(values),
    })
      .then(() => {
        setFormSubmitted(true);
      })
      .catch((error) => console.log({ error }));
  };

  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <ResponsiveContext.Consumer>
          {(responsive) => (
            <Section className="contact-section" {...props}>
              <Container
                styles={responsive.isDesktopUp ? { height: '100%' } : {}}
              >
                <Grid
                  styles={responsive.isDesktopUp ? { height: '100%' } : {}}
                  gridTemplateColumns={
                    responsive.isDesktopUp ? '1fr 0.37fr' : '1fr'
                  }
                  gap={responsive.isDesktopUp ? 0 : '7.688rem'}
                >
                  <FormSection
                    className="form-section"
                    {...{ theme }}
                    responsive={responsive}
                  >
                    <FormHeading
                      className="form-heading"
                      {...{ theme }}
                      responsive={responsive}
                    >
                      {intl.formatMessage({ id: 'contact-cta' })}
                    </FormHeading>

                    <Formik
                      initialValues={{
                        fullName: '',
                        email: '',
                        message: '',
                        'form-name': 'contactForm',
                        agreedToPrivacyPolicy: false,
                      }}
                      validationSchema={ContactSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ errors, touched }) => (
                        <Form data-netlify="true" name="contactForm">
                          <Field type="hidden" name="form-name" />
                          <StyledField
                            {...{ theme }}
                            placeholder={intl.formatMessage({ id: 'fullName' })}
                            id="fullName"
                            name="fullName"
                            type="text"
                            error={touched.fullName && errors.fullName}
                          />
                          {touched.fullName && errors.fullName && (
                            <Feedback {...{ theme }}>
                              {errors.fullName}
                            </Feedback>
                          )}
                          <StyledField
                            {...{ theme }}
                            placeholder={intl.formatMessage({ id: 'email' })}
                            id="email"
                            name="email"
                            type="email"
                            error={touched.email && errors.email}
                          />
                          {touched.email && errors.email && (
                            <Feedback {...{ theme }}>{errors.email}</Feedback>
                          )}
                          <Label {...{ theme }}>
                            {intl.formatMessage({ id: 'message' })}
                          </Label>
                          <StyledField
                            {...{ theme }}
                            id="message"
                            name="message"
                            as="textarea"
                            error={touched.message && errors.message}
                          />
                          {touched.message && errors.message && (
                            <Feedback {...{ theme }}>{errors.message}</Feedback>
                          )}
                          <CheckboxLabel {...{ theme }}>
                            <StyledField
                              {...{ theme }}
                              type="checkbox"
                              id="agreedToPrivacyPolicy"
                              name="agreedToPrivacyPolicy"
                            />
                            {intl.formatMessage({ id: 'agree' })}
                            <a
                              href="privacy-policy"
                              target="_blank"
                              className="privacy-policy-link"
                            >
                              {intl.formatMessage({ id: 'privacy' })}
                            </a>
                          </CheckboxLabel>
                          {touched.agreedToPrivacyPolicy &&
                            errors.agreedToPrivacyPolicy && (
                              <Feedback {...{ theme }}>
                                {errors.agreedToPrivacyPolicy}
                              </Feedback>
                            )}

                          <SubmitButton
                            className="contact__submit"
                            {...{ theme }}
                            type="submit"
                          >
                            {intl.formatMessage({ id: 'submit' })}
                          </SubmitButton>
                          {formSubmitted && (
                            <Feedback {...{ theme }}>
                              {intl.formatMessage({
                                id: 'thanks-for-contacting-us',
                              })}
                            </Feedback>
                          )}
                        </Form>
                      )}
                    </Formik>
                  </FormSection>

                  <div className="contact__info-wrapper">
                    <ContactInfoHeading
                      className="contact__info-heading"
                      {...{ theme }}
                      responsive={responsive}
                    >
                      {intl.formatMessage({ id: 'contactus' })}
                    </ContactInfoHeading>

                    {/* <SocialLink
                      className="contact__link-social"
                      {...{ theme }}
                      href="https://www.instagram.com/bengaru.co/"
                    >
                      Instagram
                    </SocialLink>
                    <SocialLink
                      className="contact__link-social"
                      {...{ theme }}
                      href="https://www.behance.net/bengaruagency"
                    >
                      Behance
                    </SocialLink> */}

                    <div className="contact__links">
                      <ContactLink
                        className="contact__link"
                        {...{ theme }}
                        target="_blank"
                        href={`mailto:${intl.formatMessage({
                          id: 'contact-email',
                        })}`}
                      >
                        {`${intl.formatMessage({
                          id: 'emailAbrev',
                        })} - ${intl.formatMessage({ id: 'contact-email' })}`}
                      </ContactLink>
                      <ContactLink
                        className="contact__link"
                        {...{ theme }}
                        href="tel:+524421060888"
                      >
                        {`${intl.formatMessage({
                          id: 'phoneAbrev',
                        })} - +52 442 106 0888`}
                      </ContactLink>
                    </div>

                    <CopyRightWrapper
                      responsive={responsive}
                      justifyContent="space-between"
                    >
                      <CopyRight {...{ theme }}>
                        {`© ${currentYear} Bengaru Agency. ${intl.formatMessage(
                          {
                            id: 'copyright',
                          },
                        )}`}
                      </CopyRight>

                      <SVGWrapper color={theme.contrastColor}>
                        <StartTinySVG />
                      </SVGWrapper>
                    </CopyRightWrapper>
                  </div>
                </Grid>
              </Container>
            </Section>
          )}
        </ResponsiveContext.Consumer>
      )}
    </ThemeContext.Consumer>
  );
};

export default Contact;
